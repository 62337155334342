import React from 'react'
import { WorkGrid, TopIntro } from '$components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Col } from '../components/TopIntro'

const Jon = () => (
  <Layout>
    <SEO title="Work" />

    <TopIntro variation="workColor">
      <Col>
        <h1>work</h1>
        <p>
        I am currently a full-time freelancer. <a href="mailto:me@belgiles.com" target="_blank">Drop me a line</a> if you 
        <p>have a project or an idea you’d like to chat about.</p>
        </p>
      </Col>
    </TopIntro>

    <WorkGrid noHeading />
  </Layout>
)

export default Jon
